.dsendp_main-container {
    width: 360px;
    background-color: white;
    border-radius: 24px;
    margin: 20px;
    padding: 24px;
    position: relative;
    outline: none;
}

.dsendp_main-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.dsendp_main-container .content .close-btn {
    width: 24px;
    align-self: flex-end;
    cursor: pointer;
}

.dsendp_main-container .content .title {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.dsendp_main-container .content .sub-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 32px;
}

.dsendp_main-container .content .streak-balance {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #64748B;
    background: #E2E8F0;
}

.dsendp_main-container .content .action-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 16px;
}

.dsendp_main-container .content .action-btns .back-btn {
    width: 120px;
    padding: 7px 40px;
}

.dsendp_main-container .content .action-btns .end-btn {
    width: 120px;
    padding: 7px 40px;
}