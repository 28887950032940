.paginatorContainer {
    text-align: center;
    margin-top: 10px;
    padding-right: 12px;
    padding-left: 15px;
}

.showMoreButton {
    padding: 10px 0;
}
