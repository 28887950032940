.pigContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 9999;
}

.flyingPig {
    position: absolute;
    bottom: -5px;
    opacity: 0;
    animation: floatUp 2.6s ease-out forwards;
}

@keyframes floatUp {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    60% {
        opacity: 0.75;
    }
    100% {
        transform: translateY(-30vh);
        opacity: 0;
    }
}