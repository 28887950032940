.dspb_progress-bar-container {
    display: flex;
    position: relative;
}

.dspb_progress-bar-container .fire-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dspb_progress-bar-container .fire-icons .fire-item {
    height: 66px;
    display: flex;
    position: relative;
}

.dspb_progress-bar-container .fire-icons .fire-item .reward {
    position: absolute;
    top: 40px;
    width: 56px;
    display: flex;
    justify-content: center;
    left: -12px
}
