.friends_main-container .bottom-container .my-referrals {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.80);
    background: rgba(255, 255, 255, 0.40);
    box-shadow: 2px 4px 12px 0px rgba(255, 150, 43, 0.07);
}

.friends_main-container .bottom-container .my-referrals .heading {
    color: #0F172A;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
}

.friends_main-container .bottom-container .my-referrals .no-data {
    color: #1E293B;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.friends_main-container .bottom-container .my-referrals .state {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.friends_main-container .bottom-container .my-referrals .state .text {
    color: #0F172A;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 12px;
}


.friends_main-container .bottom-container .my-referrals .earnings {
    display: flex;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
    gap: 3px
}


.friends_main-container .bottom-container .my-referrals .earnings .text {
    color: #1E293B;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}


.friends_main-container .bottom-container .my-referrals .show-all {
    width: 100%;
    color: #1E293B;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    text-decoration-line: underline;
}