.referral-item .info-text {
    color: #1E293B;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
}

.referral-item .info-text.ready-to-redeem {
    color: #F59E0B;
}

.referral-item .info-text img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}