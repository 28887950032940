.friends_main-container .referral-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 16px;
    align-self: stretch;
    background-color: white;
    border-radius: 20px;
    transform: translateY(-24px);
}

.friends_main-container .referral-info .heading {
    color: #27272A;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.friends_main-container .referral-info .sub-heading {
    color: #27272A;
    font-size: 24px;
    font-weight: 400;
    margin-top: -24px;
    text-align: center;
}

.friends_main-container .referral-info .sub-heading img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}


.friends_main-container .referral-info .info-text {
    max-width: 400px;
    color: #27272A;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.friends_main-container .referral-info .info-text img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.friends_main-container .referral-info .share-text {
    color: #71717A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.friends_main-container .referral-info .copy-link {
    width: 100%;
    padding: 4px 4px 4px 16px;
    margin-top: -20px;
}

.friends_main-container .referral-info .copy-link .copy-btn {
    background: #FB923C;
    color: white
}

.friends_main-container .referral-info .copy-link .copy-btn.disabled {
    background: #D5D5D5;
    color: #c0c0c0
}


.friends_main-container .referral-info .share-options {
    display: flex;
    gap: 16px;
    margin: auto;
}


.friends_main-container .referral-info .share-options .option {
    max-width: 56px;
    min-width: 56px;
    height: 56px;
    border-radius: 100px;
    border: 1px solid #e5e7eb;
}

.friends_main-container .referral-info .share-options .option.disabled {
    opacity: 0.5;
}