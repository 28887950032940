.dsc_main-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.dsc_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgba(255, 255, 255, 0.80) !important;
  box-shadow: -2px -4px 12px 0px rgba(255, 169, 6, 0.1), 2px 4px 12px 0px rgba(255, 169, 6, 0.1) !important;
  border-radius: 20px !important;
  border: 2px solid white !important;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 12px !important;
  opacity: 0.9;
}

.dsc_container.showTime {
  border-radius: 20px 0px 20px 20px !important;
}

.dsc_content_container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 12px;
  border: none !important;
  width: 100% !important;
  padding: 0px;
}

.dsc_content_container .fire-icon {
  width: 100px;
}

.dsc_content_container .title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dsc_content_container .title-container .title {
  color: #1f1f1f;
  font-weight: 500;
  text-wrap: wrap;
}

.dsc_content_container .title-container .sub-title {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #636874;
  text-wrap: wrap;
}

.dsc_content_container .title-container .sub-title .time-to-reset {
  border-radius: 12px;
  padding: 4px 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.50);
}

.dsc_content_container .title-container .sub-title .time-to-reset img {
  width: 12px;
  height: 12px;
  transform: translateY(-8%);
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7307%) hue-rotate(76deg) brightness(108%) contrast(100%);
}

.dsc_content_container .title-container .sub-title .expired {
  color: red;
}

.dsc_content_container .rewards {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
}

.dsc_content_container .rewards.info {
  align-self: flex-start;
}

.dsc_content_container .rewards .actionButton {
  width: max-content;
  padding: 6px 16px;
}

.dsc_container .progress-bar {
  margin: 12px 12px 0px 12px;
}

.revive-timer {
  width: auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 15px 15px 0px 0px;
  background: var(--pink-to-gold, linear-gradient(95deg, #ff6492 1.76%, #ffa906 97.15%));
  color: white;
  padding: 2px 20px;
  font-style: italic;
  font-size: 12px;
}

.revive-timer img {
  width: 14px;
  height: 14px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7307%) hue-rotate(76deg) brightness(108%) contrast(100%);
}

.revive-timer strong {
  min-width: 52px;
}

@media screen and (min-width: 420px) {
  .revive-timer {
    font-size: 14px;
  }

  .revive-timer img {
    width: 16px;
    height: 16px;
  }

  .revive-timer strong {
    min-width: 62px;
  }
}

@media screen and (min-width: 596px) {
  .revive-timer {
    font-size: 16px;
  }

  .revive-timer img {
    width: 18px;
    height: 18px;
  }

  .revive-timer strong {
    min-width: 72px;
  }
}