.rewardItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #00000033;
    padding-top: 8px;
    margin-top: 16px;
}

.itemSwipe {
    animation: alertOut 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    pointer-events: none;
}

.itemSwipe.hidden {
    visibility: hidden;
}

.rewardItemContent {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.rewardItemIcon {
    width: 58px;
    height: 58px;
    border-radius: 8px;
}

.rewardItemInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    padding-left: 6px;
}

.rewardItemInfo .complete {
    color: #039F76;
}

.rewardItemInfo .subtitle {
    color: #636874;
}

.rewardItemRewardSection {
    display: flex;
    flex-direction: column;
    align-self: start;
    align-items: flex-end;
    gap: 12px;
}