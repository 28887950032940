.modal-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #52525b;
  padding: 15px;
  padding-top: 10px;
}

.modal-button button {
  background: #fb923c;
  color: white;
}

.modal-button:hover {
  background: #fb923c;
}
