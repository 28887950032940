.gameDetailsInner {
  height: 100%;
  min-height: calc(100svh - 82px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 20px;
}

.gameDetailsButtonInner {
  background: linear-gradient(0deg, #fff9f7, #fff9f7) padding-box,
    linear-gradient(
        269.83deg,
        rgba(255, 100, 146, 0.07) 3.45%,
        #ff8f50 54.5%,
        rgba(255, 181, 22, 0.14) 99.73%
      )
      border-box;
  position: sticky;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  z-index: 1000;
  padding: 12px 16px;
  box-shadow: none;
  border-top: 1px solid transparent;
}

.gameDetailsInner.bounce {
  animation: bounce-in 0.5s ease;
  animation-delay: 0.1s;
}

.gameDetailsWrapper.disabled {
  pointer-events: none;
}

.gameDetailsContent.bounce {
  animation: bounce-in 0.5s ease;
  animation-delay: 0.1s;
}

@keyframes bounce-in {
  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.gameDetailsGameInfo {
  padding: 16px 16px 28px 26px;
  margin-top: -5px;
  background-color: #ffffff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0px -6px 24px 0px #000a1a1f;
  position: relative;
}

.gameDetailsGameInfoShadow {
  width: calc(100% - 42px);
  height: 48px;
  bottom: 15px;
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -16.67%, #ffffff 65.62%);
}

.gameDetailsContainerInner {
  border-radius: 0 0 16px 16px;
}

.gameDetailsHead {
  display: flex;
  gap: 13px;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  padding: 13px 18px 10px 25px;
}

.gameDetailsHead.noOffer {
  border-radius: 16px;
  padding: 13px 18px 20px 25px;
}

.gameDetailsHeadInner {
  z-index: 2;
  position: relative;
}

.gameDetailsHeadInner.fixed {
  position: sticky;
  width: 100%;
  top: 60px;
}

.gameDetailsHeadContent {
  margin: auto;
  margin-top: -13px;
}

.gameDetailsHeadImage {
  height: 100%;
  border-radius: 8px;
}

.gameDetailsHeadImageWrap {
  height: 100px;
  min-width: 100px;
  border-radius: 8px;
  margin-top: -63px;
  padding: 1px;
  background: var(--coin-gradient);
}

.gameDetailsContent {
  display: flex;
  flex-direction: column;
}

.gameDetailsContent .gameDetailsDescription {
  color: #000;
  overflow: hidden;
  padding-right: 25px;
}

.gameDetailsDescriptionArrow {
  position: absolute;
  left: 50%;
  transform: translateX(calc(-50% - 15px)) rotate(-90deg);
  bottom: 7px;
  cursor: pointer;
  transition: transform 300ms ease-out;
  z-index: 1;
}

.gameDetailsDescriptionArrow.expanded {
  transform: translateX(calc(-50% - 15px)) rotate(-270deg);
}

.gameDetailsDescriptionArrow.disabled {
  cursor: default;
}

@media (min-width: 600px) {
  .gameDetailsHeadInner.fixed {
    max-width: 600px;
  }
}
