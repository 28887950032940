.friends_main-container .bottom-container .influencer-info {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 20px;
    background: #FFF7ED;
}

.friends_main-container .bottom-container .influencer-info .heading {
    color: #27272A;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

.friends_main-container .bottom-container .influencer-info .info {
    color: #27272A;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 0px 24px;
}

.friends_main-container .bottom-container .influencer-info .feature {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 12px;
}

.friends_main-container .bottom-container .influencer-info .feature .number {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #27272A;
}

.friends_main-container .bottom-container .influencer-info .feature .number.text {
    color: #27272A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.friends_main-container .bottom-container .influencer-info .feature .text {
    color: #27272A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.friends_main-container .bottom-container .influencer-info .apply-btn {
    width: max-content;
    background: #FB923C;
    color: white;
    padding: 8px 20px;
}