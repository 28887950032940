.dsep_main-container {
    width: 360px;
    background-color: white;
    border-radius: 24px;
    margin: 20px;
    padding: 24px;
    position: relative;
    outline: none;
}

.dsep_main-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.dsep_main-container .content .close-btn {
    width: 24px;
    align-self: flex-end;
    cursor: pointer;
}

.dsep_main-container .content .title {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.dsep_main-container .content .sub-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 32px;
}

.dsep_main-container .content .reset-text {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    padding: auto;
}

.dsep_main-container .content .reset-text .time {
    color: #f00;
}

.dsep_main-container .content .info-text {
    color: #000;
    font-size: 14px;
    margin-top: 12px;
}

.dsep_main-container .content .revive-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: -8px;
}

.dsep_main-container .content .revive-actions .or {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.dsep_main-container .content .insufficient-currency {
    color: #000;
    font-size: 12px;
    font-style: normal;
    line-height: 22px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -10px;
}

.dsep_main-container .content .insufficient-currency img {
    vertical-align: middle;
}

.dsep_main-container .content .end-btn {
    width: 120px;
    padding: 7px 40px;
    margin-top: -14px;
}