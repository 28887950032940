.dscp_main-container {
    width: 100%;
    background-color: white;
    border-radius: 24px;
    margin: 20px;
    padding: 24px;
    position: relative;
    outline: none;
}

.dscp_main-container .fire-icon {
    position: absolute;
    z-index: 2;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.dscp_main-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
}

.dscp_main-container .content .title {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
}

.dscp_main-container .content .info {
    max-width: 90%;
    color: #000;
    font-size: 14px;
    line-height: 22px;
}

.dscp_main-container .content .gamesHeaderInput {
    min-width: 32px !important;
}

.dscp_main-container .content .priceText {
    font-size: 24px;
}

.dscp_main-container .content .time-to-reset {
    color: #94A3B8;
    font-size: 16px;
    margin-top: -10px;
}

.dscp_main-container .content .actionButton {
    font-size: 18px;
}