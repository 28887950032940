.floatingText {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400 !important;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    animation: floatUpFadeOut 1.2s ease-out forwards;
    z-index: 1;
}
.textPig {
    color: #FFAFC7!important;
}
.textCoin {
    color:#f7a900 !important;
}

@keyframes floatUpFadeOut {
    0% {
        transform: translate(-50%, 0);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    85% {
        opacity: 0.85;
    }
    100% {
        transform: translate(-50%, -10px);
        opacity: 0;
    }
}