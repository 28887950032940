.referral-item {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    border: 2px solid #FFF;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 2px 4px 12px 0px rgba(255, 150, 43, 0.07);
}

.referral-item.ready-to-redeem {
    border: 2px solid #FFCD47;
}

.referral-item.complete {
    border: 2px solid rgba(3, 159, 118, 0.60);
}

.referral-item .top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #1F2937;
}

.referral-item .top-row.ready-to-redeem {
    color: #F59E0B;
}

.referral-item .top-row.complete {
    color: #1F2937;
}

.referral-item .top-row .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.referral-item .top-row .date {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.referral-item .bonus-earned-text {
    color: #1E293B;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.referral-item .bonus-earned-text img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.referral-item .progress-bar-complete {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
}

.referral-item .progress-bar-complete .check-mark {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0
}

.referral-item .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.referral-item .progress-bar .value {
    font-size: 10px;
    font-weight: 700;
    color: #3C4249;
}

.referral-item .progress-bar .value.ready-to-redeem {
    color: #F59E0B;
}

.referral-item .progress-bar .value img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}