.aggregatedReward {
    width: 100%;
    display: flex;
    background: rgba(255, 255, 255, 0.80) !important;
    border: 2px solid white;
    border-radius: 20px !important;
    margin-bottom: 20px;
    padding-right: 20px !important;
    max-height: 100vh;
    transition: max-height 0.35s, margin-bottom 0.35s !important;
}

.aggregatedReward .MuiAlert-message {
    width: 100%;
    overflow: visible;
}

.topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.virtualCurrencyRewards {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.aggregatedReward .topSection .actionButton {
    max-width: max-content;
    padding: 8px 16px;
}

.rewardsSection {
    overflow: hidden;
}