.dsrb_main-container {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 14px;
    background: linear-gradient(180deg, #FFCD47 0%, #EB9800 100%);
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.dsrb_main-container.disabled {
    background: #94A3B8;
    cursor: default;
    opacity: 0.75;
}

.dsrb_main-container .fire-icon {
    margin: 4px
}

.dsrb_main-container .currency {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 4px;
    padding: 4px;
}

.dsrb_main-container .currency span {
    color: #292D38;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
}

.dsrb_main-container .currency img {
    width: 14px;
}