.dswp_main-container {
    width: 360px;
    background-color: white;
    border-radius: 24px;
    margin: 20px;
    padding: 24px;
    position: relative;
    outline: none;
}

.dswp_main-container .fire-icon {
    position: absolute;
    z-index: 2;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.dswp_main-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.dswp_main-container .content .title {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
}

.dswp_main-container .content .sub-title {
    color: #000;
    font-size: 14px;
}

.dswp_main-container .content .close-btn {
    width: 24px;
    align-self: flex-end;
    cursor: pointer;
}