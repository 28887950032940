.pointInputInner {
  cursor: pointer;
  height: max-content;
  border-radius: 100px;
  padding: 1px;
  margin: auto 0;
  position: relative;
  margin-right: 15px;
}

.pointInputImage {
  position: absolute;
  height: 45px;
  right: -15px;
  top: -7px;
  transition: height 25ms;
  z-index: 10000;
}

.pointInputImage.grow {
  height: 50px;
}

.pointInputInner.coin {
  background: var(--coin-gradient);
}

.pointInputInner.pig {
  background: var(--pig-gradient);
}

.pointInput {
  border-radius: 100px;
  padding: 5px 25px 5px 7px;
  display: flex;
  justify-content: space-between;
  gap: 9px;
  min-width: 64px;
}

.pointInput.gamesHeaderInput {
  min-width: 113px;
}

.pointInput.coin {
  background: linear-gradient(0deg, #ffedd5, #ffedd5);
  padding: 5px 30px 5px 7px;
}

.pointInput.pig {
  background: linear-gradient(0deg, #fce7f3, #fce7f3);
  padding: 5px 35px 5px 7px;
}

@media (max-width: 640px) {
  .pointInput.gamesHeaderInput {
    min-width: 91px;
  }

  .pointInputImage {
    height: 40px;
  }

  .pointInputImage.grow {
    height: 45px;
  }

  .pointInput.coin {
    background: linear-gradient(0deg, #ffedd5, #ffedd5);
    padding: 5px 25px 5px 7px;
  }

  .pointInput.pig {
    background: linear-gradient(0deg, #fce7f3, #fce7f3);
    padding: 5px 30px 5px 7px;
  }
}
