.dsi_main-container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;
}

.dsi_main-container .background {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 16px;
    z-index: 1;
}

.dsi_main-container .content-container {
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding: 24px
}

.dsi_main-container .content-container .content {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

}

.dsi_main-container .content-container .content .heading {
    text-align: center;
    color: black;
    font-size: 24px;
    font-weight: 700;
}

.dsi_main-container .content-container .content .sub-title {
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.dsi_main-container .content-container .content .info {
    text-align: center;
    color: #475569;
    font-size: 12px;
    font-weight: 500;
}

.dsi_main-container .content-container .content .info .time-to-reset {
    border-radius: 12px;
    padding: 4px 8px;
    color: white;
    background-color: rgba(0, 0, 0, 0.50);
}

.dsi_main-container .content-container .content .info .time-to-reset img {
    width: 14px;
    height: 14px;
    transform: translateY(16%);
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7307%) hue-rotate(76deg) brightness(108%) contrast(100%);
}

.dsi_main-container .content-container .content .progress-bar {
    width: 100%;
    margin-top: 24px;
    padding: 24px
}

.dsi_main-container .content-container .actionButton {
    width: max-content;
    padding: 6px 24px;
    align-self: center;
    margin-bottom: 32px;
}