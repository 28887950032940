.friends_main-container {
    transform: translateY(-20px);
}

.friends_main-container .hero {
    width: 100%;
}


.friends_main-container .bottom-container {
    display: flex;
    flex-direction: column;
    margin: 16px;
    gap: 16px;
    transform: translateY(-20px)
}