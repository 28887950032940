.fire-icon {
    display: flex;
    position: relative;
    height: max-content;
}

.fire-icon.header {
    width: 26px !important;
}


@media (min-width: 380px) {
    .fire-icon.header {
        width: 30px !important;
    }
}

@media (min-width: 640px) {
    .fire-icon.header {
        width: 34px !important;
    }
}

.fire-icon img {
    width: 100%;
}

.fire-icon img.animate {
    animation: zoom-icon 700ms forwards;
}

.fire-icon .fire-animation {
    display: flex;
    position: absolute;
    height: max-content;
    opacity: 0;
}

.fire-icon .fire-animation.animate {
    animation: slideOutUp 700ms forwards;
}

.fire-icon .value {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fire-icon .value.animate {
    animation: slideOutLeft 500ms 1s forwards;
}

.fire-icon .value2 {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2)
}

.fire-icon .value2.animate {
    animation: zoom-value 700ms forwards;
}

@keyframes slideOutLeft {
    from {
        transform: translate(-50%, -50%);
        opacity: 1;
    }

    to {
        transform: translate(-80vw, -50%);
        opacity: 0;
    }
}


@keyframes zoom-icon {


    0%,
    39% {
        transform: scale(1);
    }

    40% {
        transform: scale(0.85);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slideOutUp {

    0%,
    50% {
        transform: translate(-5%, -10%);
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        transform: translate(-5%, -42%);
        opacity: 1;
    }
}

@keyframes zoom-value {
    0% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }

    20% {
        transform: translate(-50%, -100%) scale(3);
        opacity: 1;
    }

    40% {
        transform: translate(-50%, -50%) scale(0.75);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}